import { sendMessToApp } from "../../support";
import PropTypes from "prop-types";
import { Avatar, Badge, ConfigProvider, Divider } from "antd";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { MessageOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import notificationApi from "../../api/notificationAPI";

HeaderLayout.propTypes = {
  text: PropTypes.string,
  showLogo: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

HeaderLayout.defaultProps = {
  text: "",
  showLogo: false,
  className: "",
};

export default function HeaderLayout({
  text,
  className,
  color = `rgba(51,206,255,0.5)`,
  link,
  showDevider = true,
  showChat,
  showNoti = false,
  teamId = null,
  showTutorial = false,
  startTutorial = null,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    if (link) {
      navigate(link);
      return;
    }
    return sendMessToApp("EXIT_COMMAND");
  };
  const { data: dataNoti } = useQuery(
    "notification-info",
    () => notificationApi.getNofitication(),
    { select: (val) => val.data, enabled: showNoti }
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Divider: {
            margin: 0,
          },
        },
      }}
    >
      <header
        style={{
          height: "80px",
          backgroundImage: `linear-gradient(180deg, ${color} 0%, #fff0 100%)`,
        }}
        className={"container mb-1 " + className}
      >
        <div className="d-flex justify-content-start align-items-center">
          <div className="btn-back" onClick={handleBack}>
            <img
              srcSet="/img/back.png 2x"
              alt="back"
              style={{ width: "100%" }}
            />
          </div>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>{text}</span>
        </div>
        <div>
          {showChat && (
            <Link to={`/my-team/chat/${teamId}`}>
              <Badge size="small" offset={[-6, 6]}>
                <Avatar
                  icon={<MessageOutlined />}
                  style={{ background: "transparent", color: "#01A7DB" }}
                />
              </Badge>
            </Link>
          )}
          {showTutorial && (
            <Avatar
              icon={<QuestionCircleOutlined />}
              style={{
                background: "transparent",
                color: "#01A7DB",
                fontSize: "20px",
                marginTop: "5px",
                marginRight: '8px'
              }}
              onClick={startTutorial}
            />
          )}
          {showNoti && (
            <Link to={"/notification"}>
              <Badge
                size="small"
                offset={[-6, 6]}
                count={dataNoti?.result?.data?.totalUnread ?? 0}
              >
                <Avatar
                  shape="square"
                  src="/img/team/ring.png"
                  style={{ width: "20px", height: "auto" }}
                />
              </Badge>
            </Link>
          )}
        </div>
      </header>
      {showDevider && (
        <Divider style={{ height: "5px", background: "#F6F6F7", margin: 0 }} />
      )}
    </ConfigProvider>
  );
}
