import axiosClient from "./axiosClient";
import axiosNotification from "./axiosNotification";

const notificationApi = {
  getNofitication(params) {
    const url = "authentication/notifications";
    return axiosNotification.get(url, { params: {...params,Type: 18 } }); //Type = 18: Thong bao cua NewMe 2023
  },
  getNofiticationDetail(id) {
    const url = "authentication/notifications/"+id;
    return axiosNotification.get(url); 
  },
  getNofiticationResign(id) {
    const url = "user/request-resign/current-request";
    return axiosClient.get(url); 
  },
  putReadNotiById(id){ 
    const url = "authentication/notifications/read/"+id;
    return axiosNotification.post(url);
  },
  postReadAllNoti(data){ 
    const url = "authentication/notifications/read-all";
    return axiosNotification.post(url, data);
  }
};

export default notificationApi;
