import Lottie from "lottie-react";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Loading from "./lotties/loading2023.json";
import Layout from "./pages/Layout";
import NotFoundPage from "./pages/NotFoundPage";

const Home = React.lazy(() => import("./pages/Home"));
const ListNewsFeed = React.lazy(() =>
  import("./containers/NewsFeed/ListNewsFeed")
);
const NewsFeedCreate = React.lazy(() =>
  import("./containers/NewsFeed/NewsFeedCreate")
);
const Tutorial = React.lazy(() => import("./containers/Tutorial/Tutorial"));
const Teams = React.lazy(() => import("./containers/Teams/Teams"));
const CreateTeamForm = React.lazy(() =>
  import("./containers/Teams/components/CreateTeamForm")
);
const TeamsChat = React.lazy(() =>
  import("./containers/Teams/message/MessageTeam")
);
const ImagesByDay = React.lazy(() => import("./containers/Teams/ImagesByDay"));
const ImagesTeamOverview = React.lazy(() =>
  import("./containers/Teams/ImagesTeamOverview")
);
const JoinTeamForm = React.lazy(() =>
  import("./containers/Teams/components/JoinTeamForm")
);
const Profile = React.lazy(() => import("./containers/Profile/Profile"));
const MemberProfile = React.lazy(() =>
  import("./containers/Teams/member/MemberProfile")
);
const Library = React.lazy(() => import("./containers/Library/Library"));
const Rank = React.lazy(() => import("./containers/Ranks/Rank"));
const RankBoard = React.lazy(() => import("./containers/Ranks/RankBoard"));
const RankFinal = React.lazy(() => import("./containers/Ranks/RankFinal"));
const Notification = React.lazy(() =>
  import("./containers/Notification/Notification")
);
const NotificationDetail = React.lazy(() =>
  import("./containers/Notification/NotificationDetail")
);
const TermPage = React.lazy(() => import("./containers/Introduce/Introduce"));
const TicketTermPage = React.lazy(() =>
  import("./containers/TicketTermPage/TicketTermPage")
);

function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={Loading} autoPlay loop />
          </div>
        }
      >
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/my-team" element={<Teams />} />
            <Route path="/my-team/join" element={<JoinTeamForm />} />
            <Route path="/my-team/add" element={<CreateTeamForm />} />
            <Route
              path="/my-team/member-profile/:id"
              element={<MemberProfile />}
            />
            <Route
              path="/my-team/images-by-day/:id"
              element={<ImagesByDay />}
            />
            <Route
              path="/my-team/image-overview/:id"
              element={<ImagesTeamOverview />}
            />
            <Route path="/my-team/chat/:id" element={<TeamsChat />} />
            <Route path="/news-feed" element={<ListNewsFeed />} />
            <Route path="/news-feed/add" element={<NewsFeedCreate />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/library" element={<Library />} />
            <Route path="/ranks" element={<Rank />} />
            <Route path="/ranks-board" element={<RankBoard />} />
            <Route path="/ranks-final" element={<RankFinal />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/notification/:id" element={<NotificationDetail />} />
            <Route path="/term" element={<TermPage />} />
            <Route path="/ticket-term" element={<TicketTermPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
