import axios from "axios";
import { sendMessToApp } from "../support";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token-new-me");

    config.headers.Authorization = "Bearer " + token;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(error?.response?.status === 401){
      sendMessToApp("EXIT_COMMAND");
    }
    if(error?.response?.status === 411){
      sendMessToApp("SECOND_ACCOUNT");
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
